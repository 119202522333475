import React from "react";
import { Container, Row, Col } from "reactstrap";
import Layout from "../components/layout";
import Gallery from "react-photo-gallery";
import Lightbox from "react-images";


const photos = [
  {
    src: '/gallery/0.jpg',
    width: 4,
    height: 3,
    alt: "Interbuildings Projects Image Gallery"
  },
  {
    src: '/gallery/1.jpg',
    width: 4,
    height: 3,
    alt: "Interbuildings Projects Image Gallery"
  },
  {
    src: '/gallery/2.jpg',
    width: 4,
    height: 3,
    alt: "Interbuildings Projects Image Gallery"
  },
  {
    src: '/gallery/3.jpg',
    width: 4,
    height: 3,
    alt: "Interbuildings Projects Image Gallery"
  },
  {
    src: '/gallery/4.jpg',
    width: 4,
    height: 3,
    alt: "Interbuildings Projects Image Gallery"
  },
  {
    src: '/gallery/5.jpg',
    width: 4,
    height: 3,
    alt: "Interbuildings Projects Image Gallery"
  },
  {
    src: '/gallery/6.jpg',
    width: 4,
    height: 3,
    alt: "Interbuildings Projects Image Gallery"
  },
  {
    src: '/gallery/7.jpg',
    width: 4,
    height: 3,
    alt: "Interbuildings Projects Image Gallery"
  },
  {
    src: '/gallery/8.jpg',
    width: 4,
    height: 3,
    alt: "Interbuildings Projects Image Gallery"
  },
  {
    src: '/gallery/9.jpg',
    width: 4,
    height: 3,
    alt: "Interbuildings Projects Image Gallery"
  },
];
class GatsGall extends React.Component {
  constructor() {
    super();
    this.state = { currentImage: 0 };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  }
  render() { 
    return (
      <div className="imageGallery p-4">
        <Gallery
          photos={photos}
          direction={"column"}
          columns={"4"}
          onClick={this.openLightbox}
        />
        <Lightbox
          images={photos}
          onClose={this.closeLightbox}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
        />
      </div>
    );
  }
}

const GalleryPage = ({ data }) => {
  return (
    <Layout>
      <div className="hero internal py-3">
        <div className="bgimage">
          <Container>
          <Row>
            <Col md="12">
              <h1 className="pl-4 pl-4 p-0 m-0 ml-2">Gallery</h1>
            </Col>
          </Row>
          </Container>
        </div>
      </div>

      <Container className="">
        <Row>
          <Col md="12 pl-4 p-0 m-0">
            <h4 className="mb-4 mt-4 hidden">GALLERY</h4>
            <GatsGall />
          </Col>
        </Row>
      </Container>
    </Layout> 
  );
};
export default GalleryPage;
